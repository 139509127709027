import React from "react";
import { useParams } from "react-router-dom";

import Details from "./Details";
import Header from "../Header";

function InterviewDetails(props) {
  const { iid } = useParams();

  return (
    <div className="Main">
      <Header />
      <Details iid={iid} />
    </div>
  );
}

export default InterviewDetails;

import React from "react";
import { useState, useContext } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import MapsUgcRoundedIcon from "@mui/icons-material/MapsUgcRounded";

import Snack from "../Snack";
import { GlobalContext } from "../../Context";

function Feedback() {
  const [dialog, setDialog] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);

  const feedbackURL = "https://app.interviewbot.ai:5000/feedback";

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarDuration, setSnackbarDuration] = useState(3500);

  function handleFeedback() {
    setDialog(false);
    fetch(feedbackURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Auth-Token": globalCtx.jwtToken,
      },
      body: JSON.stringify({
        feedback: feedback,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setSnackbarMessage("Success!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } else if (res.status === 400) {
          console.log("Bad Request");
          setSnackbarMessage("Bad request!");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        } else if (res.status === 401) {
          console.log("Authentication Error");
          setSnackbarMessage("Authentication Error!");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        } else if (res.status >= 500) {
          console.log("Internal Server Error");
          setSnackbarMessage("Internal server error");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleDialogClose() {
    setDialog(false);
  }

  function handleFeedbackChange(event) {
    setFeedback(event.target.value);
  }

  return (
    <Box
      sx={{
        position: "absolute",
        textAlign: "center",
        bottom: {
          xs: "5px",
          sm: "8px",
          md: "15px",
        },
        right: {
          xs: "5px",
          sm: "8px",
          md: "15px",
        },
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Snack
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        duration={snackbarDuration}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
      <Dialog open={dialog} keepMounted onClose={handleDialogClose}>
        <DialogTitle>Report a problem</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Interview Bot still in private beta stage. We appreciate your
            feedback to improve our product.
          </DialogContentText>
          <p></p>
          <TextField
            id="feedback"
            label="Feedback"
            onChange={handleFeedbackChange}
            multiline
            rows={4}
            helperText="Please provide your feedback here."
            size="medium"
            fullWidth
          />
          <DialogActions>
            <Button variant="outlined" onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button variant="outlined" onClick={handleFeedback} autoFocus>
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <IconButton
        aria-label="Example"
        onClick={() => {
          setDialog(true);
        }}
      >
        <MapsUgcRoundedIcon color="secondary" sx={{ fontSize: 40 }} />
      </IconButton>
    </Box>
  );
}

export default Feedback;

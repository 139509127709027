import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Main from "./Main";
import Signup from "./components/Signup/";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Protected from "./components/Protected";
import PreForm from "./components/PreForm";
import InterviewDetails from "./components/InterviewDetails";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

function App() {
  return (
    <div className="App" id="app">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Protected>
                <PreForm />
              </Protected>
            }
          />
          <Route
            path="/interview/:iid"
            element={
              <Protected>
                <InterviewDetails />
              </Protected>
            }
          />
          <Route
            path="/main"
            element={
              <Protected>
                <Main />
              </Protected>
            }
          />
          <Route path="/signup" element={<Signup themeMode="light" />} />
          <Route path="/login" element={<Login themeMode="light" />} />
          <Route
            path="/forgot-password"
            element={<ForgotPassword themeMode="light" />}
          />
          <Route
            path="/reset-password"
            element={<ResetPassword themeMode="light" />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

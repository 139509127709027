import React, { useEffect, useState, useContext } from "react";

import { GlobalContext } from "../../Context";

import { Box, Grid } from "@mui/material";
import InterviewCard from "./InterviewCard";
import InterviewScript from "./InterviewScript";
import InterviewFeedback from "./InterviewFeedback";

function Details({ iid }) {
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  const [interview, setInterview] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://app.interviewbot.ai:5000/interviews/" + iid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Auth-Token": globalCtx.jwtToken,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          console.log("Bad Request");
        } else if (res.status === 404) {
          console.log("Not Found");
        } else if (res.status === 401) {
          console.log("Authentication Error");
        } else if (res.status >= 500) {
          console.log("Internal Server Error");
        }
      })
      .then((data) => {
        setLoading(false);
        if (data) {
          setInterview(data.record);
        }
      })
      .catch((error) => {
        console.log(`Error: ${error}`);
      });
  }, []);

  return (
    <Box>
      <br />
      {interview ? (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InterviewCard interview={interview} />
          </Grid>
          <Grid item xs={4}>
            <InterviewScript iid={interview.id} />
          </Grid>
          <Grid item xs={4}>
            <InterviewFeedback interview={interview} />
          </Grid>
        </Grid>
      ) : loading ? (
        "Loading..."
      ) : (
        "No data."
      )}
    </Box>
  );
}

export default Details;

import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import { Drawer, Link, SvgIcon } from "@mui/material";

import { GlobalContext } from "../Context";
import ProfileDrawer from "./ProfileDrawer";
import Notifications from "./Notifications";
import { useCookies } from "react-cookie";

export default function MenuAppBar() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  const { isLoggedIn } = globalCtx;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [cookies, removeCookie] = useCookies(["session"]);

  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (e) => {
    setAnchorElUser(null);
  };

  const handleInterviewBot = () => {
    setAnchorElUser(null);
    navigate("/");
  };

  const handleProfile = () => {
    setAnchorElUser(null);
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    setGlobalCtx((prev) => ({
      ...prev,
      isLoggedIn: false,
      jwtToken: "",
    }));
    if (cookies.session) {
      removeCookie("session");
    }
    navigate("/login");
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        // minWidth: {
        //   xs: "190vh",
        //   md: "190vh",
        //   lg: "190vh",
        //   // xl: "82.5vh",
        // },
      }}
    >
      <AppBar position="static" style={{ backgroundColor: "#2f3337" }}>
        <Toolbar>
          <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
            <Link href="/" style={{ textDecoration: "none", color: "#fff" }}>
              <strong style={{ color: "#f48225" }}>Interview</strong>Bot{" "}
              <Typography variant="caption" gutterBottom>
                {" "}
                Beta
              </Typography>
            </Link>
          </Typography>
          {isLoggedIn && (
            <div id="header-menu">
              <Box sx={{ flexGrow: 0, m: 1 }}>
                <Notifications />
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Bot Settings" src="/bot.png" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    key="profile"
                    onClick={handleProfile}
                    value="profile"
                  >
                    <Typography textAlign="center">Profile</Typography>
                  </MenuItem>
                  <MenuItem
                    key="interview_bot"
                    onClick={handleInterviewBot}
                    value="interview_bot"
                  >
                    <Typography textAlign="center">New Interview</Typography>
                  </MenuItem>

                  <MenuItem key="logout" onClick={handleLogout} value="logout">
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        PaperProps={{
          sx: { width: "30%" },
        }}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <ProfileDrawer />
      </Drawer>
    </Box>
  );
}

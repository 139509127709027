import React, { useContext, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import Loading from "../../Loading";
import StarRating from "./StarRating";

import { GlobalContext } from "../../../Context";

export default function SubmitDialog({
  dialogOpen,
  setDialogOpen,
  payload,
  setSnackbarDuration,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}) {
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);

  const [relevancyRating, setRelevancyRating] = useState(0);
  const [overallRating, setOverallRating] = useState(0);
  const [userFeedback, setUserFeedback] = useState("");

  function handleDialogClose() {
    setDialogOpen(false);
  }

  function handleDialogSubmit() {
    setGlobalCtx((prev) => ({
      ...prev,
      isLoading: true,
    }));

    // Additional payloads
    payload.user_rate = [relevancyRating, overallRating];
    payload.user_feedback = userFeedback;
    // Update interview
    fetch(
      "https://app.interviewbot.ai:5000/interviews/" + globalCtx.interviewID,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": globalCtx.jwtToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => {
        setGlobalCtx((prev) => ({
          ...prev,
          isLoading: false,
        }));

        setSnackbarDuration(3000);

        if (res.status === 200) {
          setDialogOpen(false);
          setSnackbarMessage("Submit successful!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } else if (res.status === 404) {
          setSnackbarMessage("Resource not found!");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        } else if (res.status === 401) {
          setSnackbarMessage("Authentication error!");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        } else if (res.status >= 500) {
          setSnackbarMessage("Internal server error");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        setGlobalCtx((prev) => ({
          ...prev,
          isLoading: false,
        }));

        setSnackbarMessage("Error in request");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.log(`Error: ${error}`);
      });
    setDialogOpen(false);
  }

  return (
    <>
      {globalCtx.isLoading && <Loading />}

      <Dialog
        open={dialogOpen}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Submit Interview"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Once you submit this interview, you will be sent an email
            notification when your feedback is available.
          </DialogContentText>
          <br />
          <DialogContentText>
            Please rate your interview experience:
          </DialogContentText>
          <br />
          <StarRating
            relevancyRating={relevancyRating}
            setRelevancyRating={setRelevancyRating}
            overallRating={overallRating}
            setOverallRating={setOverallRating}
          />
          <br />
          <TextField
            id="interview-feedback"
            label="Feedback"
            onChange={(e) => setUserFeedback(e.target.value)}
            multiline
            rows={4}
            helperText="Please share your interview experience with us."
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React, { useState, useContext } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  TextField,
  Typography,
} from "@mui/material";

import { GlobalContext } from "../Context";
import Snack from "./Snack";
import Loading from "./Loading";
import InputTags from "./InputTags";

const ProfileDrawer = () => {
  const [edit, setEdit] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  const { isLoading, user, jwtToken } = globalCtx;

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState("");

  const handleSubmit = (e) => {
    setGlobalCtx((prev) => ({
      ...prev,
      isLoading: true,
    }));

    fetch(`https://app.interviewbot.ai:5000/users/${user.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Auth-Token": jwtToken,
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        roles: roles,
        password: password,
      }),
    })
      .then((res) => {
        setGlobalCtx((prev) => ({
          ...prev,
          isLoading: false,
        }));
        if (res.status === 200) {
          setSnackbarMessage("Update successful!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          res.json().then((json_data) => {
            setGlobalCtx((prev) => ({
              ...prev,
              user: json_data.record,
            }));
          });

          setEdit(false);
        } else if (res.status === 400) {
          setSnackbarMessage("Bad Request!");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        } else if (res.status === 401) {
          setSnackbarMessage("Authentication error!");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        } else if (res.status >= 500) {
          setSnackbarMessage("Internal server error");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Error in request");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.log(`Error: ${error}`);
      });
  };

  return (
    <Box component="form" sx={{ m: 2 }} noValidate autoComplete="off">
      {isLoading && <Loading />}
      <Snack
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />

      <Container direction="column">
        <Typography variant="h5" gutterBottom>
          Your Profile
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can change your profile information here.
        </Typography>
        <br />
        <Card sx={{ maxWidth: 445 }}>
          <CardMedia align="center">
            <Avatar
              alt={user.first_name}
              src="/bot.png"
              sx={{ width: 76, height: 76 }}
            />
            <Typography gutterBottom variant="body1" component="div">
              {user.email}
            </Typography>
            <Typography gutterBottom variant="body2" component="div">
              Daily Limit: {user.daily_credits}
            </Typography>
            <br />
          </CardMedia>
          <CardActions>
            {edit ? (
              <Button size="small" onClick={() => setEdit(false)}>
                Cancel
              </Button>
            ) : (
              <Button
                size="small"
                onClick={() => {
                  setEdit(true);
                  setFirstName(user.first_name);
                  setLastName(user.last_name);
                  setRoles(user.roles);
                }}
              >
                Edit
              </Button>
            )}
          </CardActions>
          <CardContent>
            <Typography gutterBottom variant="body1" component="div">
              {edit ? (
                <TextField
                  required
                  id="first-name"
                  label="First Name"
                  helperText=""
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              ) : (
                "First Name: " + user.first_name
              )}
            </Typography>
            <Typography gutterBottom variant="body1" component="div">
              {edit ? (
                <TextField
                  required
                  id="last-name"
                  label="Last Name"
                  helperText=""
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              ) : (
                "Last Name: " + user.last_name
              )}
            </Typography>
            <Typography gutterBottom variant="body1" component="div">
              {edit ? (
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  helperText="Leave blank to not update"
                  onChange={(e) => setPassword(e.target.value)}
                />
              ) : (
                "Password: ********"
              )}
            </Typography>
            <br />
            {edit && (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setEdit(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="outlined" size="small" onClick={handleSubmit}>
                  Submit
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ProfileDrawer;

/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { GlobalContext } from "../../../../Context";
import Loading from "../../../Loading";
import Snack from "../../../Snack";

const validationSchema = yup.object({
  code: yup
    .string("Enter your code")
    .trim()
    .min(36, "Please enter a valid code")
    .max(36, "Please enter a valid code")
    .required("Please enter your code"),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
});

const Form = () => {
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  const { isLoading, isLoggedIn } = globalCtx;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const navigate = useNavigate();

  const initialValues = {
    password: "",
    code: "",
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/login");
    }
  }, [globalCtx]);

  const onSubmit = (values) => {
    setGlobalCtx((prev) => ({
      ...prev,
      isLoading: true,
    }));

    fetch("https://app.interviewbot.ai:5000/reset-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BACKEND_API_KEY}`,
      },
      body: JSON.stringify({
        password: values.password,
        code: values.code,
      }),
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setSnackbarMessage("Password was reset successfully!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);

          // Login upon successful signup
          res.json().then((json_data) => {
            setGlobalCtx((prev) => ({
              ...prev,
              isLoggedIn: true,
              jwtToken: json_data.auth_token,
              user: json_data.user,
            }));
          });
        } else if (res.status === 202) {
          setSnackbarMessage("User already exist!");
          setSnackbarSeverity("info");
          setSnackbarOpen(true);
        } else if (res.status >= 400 && res.status < 500) {
          setSnackbarMessage("Bad request");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        } else if (res.status >= 500) {
          setSnackbarMessage("Internal server error");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
        setGlobalCtx((prev) => ({
          ...prev,
          isLoading: false,
        }));
      })
      .catch((error) => {
        setGlobalCtx((prev) => ({
          ...prev,
          isLoading: false,
        }));
        setSnackbarMessage("Error in request");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.log(`Error: ${error}`);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      {isLoading && <Loading />}
      <Snack
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />

      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"textSecondary"}
        >
          Reset Password
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Reset your password
        </Typography>
        <Typography color="text.secondary">
          Fill out the form to reset your password.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your new password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your private password reset code
            </Typography>
            <TextField
              label="Reset Code *"
              variant="outlined"
              name={"code"}
              fullWidth
              value={formik.values.code}
              onChange={formik.handleChange}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={"100%"}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  Already have an account?{" "}
                  <Link
                    component={"a"}
                    color={"primary"}
                    href={"/login"}
                    underline={"none"}
                  >
                    Login.
                  </Link>
                </Typography>
              </Box>
              <Button size={"large"} variant={"contained"} type={"submit"}>
                Reset
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant={"subtitle2"}
              color={"textSecondary"}
              align={"center"}
            >
              By clicking "Sign up" button you agree with our{" "}
              <Link
                component={"a"}
                color={"primary"}
                href={"/privacy"}
                underline={"none"}
              >
                Privacy policy.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;

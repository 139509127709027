import React from "react";

import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Roles } from "../../conf/roles";
import { Statuses } from "../../conf/statuses";

function InterviewCard({ interview }) {
  return (
    <Box
      sx={{
        backgroundColor: "#FAFAFA",
        borderRadius: "16px",
        padding: "12px 18px",
        height: "58vh",
        minHeight: "58vh",
        overflowY: "scroll",
      }}
    >
      <Card
        sx={{
          height: "58vh",
        }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "#E55" }} aria-label="recipe">
              {interview.role.toUpperCase().slice(0, 2)}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={Roles[interview.role]}
          subheader={interview.created_on}
        />
        {/* <CardMedia component="img" image={DataScience} alt="Data Science" /> */}
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            <b>Evaluation Score</b>:{" "}
            {interview.score ? interview.score + " / 10" : "N/A"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Duration</b>: {interview.duration ? interview.duration : "N/A"}{" "}
            mins
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Interviewer</b>:{" "}
            {interview.interviewer ? interview.interviewer : "N/A"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Status</b>:{" "}
            {interview.status ? Statuses[interview.status] : "N/A"}
          </Typography>
          <br />
          <Typography variant="body2" color="text.secondary">
            <b>User Rate: </b>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Relevance to role</b>:{" "}
            {interview.user_rate ? interview.user_rate[0] + " / 5" : "N/A"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Overall experience</b>:{" "}
            {interview.user_rate ? interview.user_rate[1] + " / 5" : "N/A"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Your feedback</b>:{" "}
            {interview.user_feedback ? interview.user_feedback : "N/A"}
          </Typography>
          <br />
          <Typography variant="body2" color="text.secondary">
            <b>Recording</b>:{" "}
            {interview.media_url ? interview.media_url : "N/A"}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Box>
  );
}

export default InterviewCard;

import { Box, Typography } from "@mui/material";
import React from "react";

const Chat = ({ item }) => {
  return (
    <Box sx={{ padding: "5px 0" }}>
      <Box sx={{ display: "flex", alignItems: "baseline" }}>
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          {item?.userName}
        </Typography>
        <Typography
          sx={{ marginLeft: "5px", fontSize: "12px", fontWeight: 400 }}
        >
          {item?.time}
        </Typography>
      </Box>
      <Typography sx={{ fontSize: "14px", fontWeight: 400, padding: "5px 0" }}>
        {item?.text}
      </Typography>
    </Box>
  );
};

export default Chat;

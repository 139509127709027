import { Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";

import { GlobalContext } from "../Context";

const Protected = (props) => {
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  const { isLoggedIn } = globalCtx;
  const currentLocation = useLocation();

  if (!isLoggedIn) {
    return <Navigate to={"/login?redirectTo=" + currentLocation.pathname} replace />;
  }

  return props.children;
};

export default Protected;

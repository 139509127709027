/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import InputTags from "../../../InputTags";
import { GlobalContext } from "../../../../Context";
import Loading from "../../../../components/Loading";
import Snack from "../../../../components/Snack";

const validationSchema = yup.object({
  firstName: yup
    .string("Enter your first name")
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  lastName: yup
    .string("Enter your last name")
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  email: yup
    .string("Enter your email")
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  tags: yup.array(),
  reference: yup
    .string("Enter your invitation code")
    .trim()
    .min(5, "Please enter a valid code")
    .max(20, "Please enter a valid code")
    .required("Please enter your invitation code"),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
});

const Form = () => {
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  const { isLoading, isLoggedIn } = globalCtx;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [roles, setRoles] = useState([]);

  const navigate = useNavigate();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    tags: [],
    password: "",
    reference: "",
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/login");
    }
  }, [globalCtx]);

  const onSubmit = (values) => {
    setGlobalCtx((prev) => ({
      ...prev,
      isLoading: true,
    }));

    fetch("https://app.interviewbot.ai:5000/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BACKEND_API_KEY}`,
      },
      body: JSON.stringify({
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        roles: roles,
        password: values.password,
        reference: values.reference,
      }),
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setSnackbarMessage("Signup successful!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);

          // Login upon successful signup
          res.json().then((json_data) => {
            setGlobalCtx((prev) => ({
              ...prev,
              isLoggedIn: true,
              jwtToken: json_data.auth_token,
              user: json_data.user,
            }));
          });
        } else if (res.status === 202) {
          setSnackbarMessage("User already exist!");
          setSnackbarSeverity("info");
          setSnackbarOpen(true);
        } else if (res.status >= 400 && res.status < 500) {
          setSnackbarMessage("Bad request");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        } else if (res.status >= 500) {
          setSnackbarMessage("Internal server error");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
        setGlobalCtx((prev) => ({
          ...prev,
          isLoading: false,
        }));
      })
      .catch((error) => {
        setGlobalCtx((prev) => ({
          ...prev,
          isLoading: false,
        }));
        setSnackbarMessage("Error in request");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.log(`Error: ${error}`);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      {isLoading && <Loading />}
      <Snack
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />

      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"textSecondary"}
        >
          Signup
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Create an account
        </Typography>
        <Typography color="text.secondary">
          Fill out the form to get started.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your first name
            </Typography>
            <TextField
              label="First name *"
              variant="outlined"
              name={"firstName"}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your last name
            </Typography>
            <TextField
              label="Last name *"
              variant="outlined"
              name={"lastName"}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Interview Bot is invitation only at this moment
            </Typography>
            <TextField
              label="Invitation Code *"
              variant="outlined"
              name={"reference"}
              fullWidth
              value={formik.values.reference}
              onChange={formik.handleChange}
              error={
                formik.touched.reference && Boolean(formik.errors.reference)
              }
              helperText={formik.touched.reference && formik.errors.reference}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={"100%"}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  Already have an account?{" "}
                  <Link
                    component={"a"}
                    color={"primary"}
                    href={"/login"}
                    underline={"none"}
                  >
                    Login.
                  </Link>
                </Typography>
              </Box>
              <Button size={"large"} variant={"contained"} type={"submit"}>
                Sign up
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant={"subtitle2"}
              color={"textSecondary"}
              align={"center"}
            >
              By clicking "Sign up" button you agree with our{" "}
              <Link
                component={"a"}
                color={"primary"}
                href={"/privacy"}
                underline={"none"}
              >
                Privacy policy.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;

import React from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";

function InterviewFeedback({ interview }) {
  return (
    <Box
      sx={{
        backgroundColor: "#FAFAFA",
        borderRadius: "16px",
        padding: "12px 18px",
        height: "58vh",
        overflowY: "scroll",
      }}
    >
      <Card>
        <Typography color="text.primary" variant="h6">
          Evaluation
        </Typography>
        <CardContent>
          {interview.feedback ? interview.feedback : "N/A"}
        </CardContent>
      </Card>
    </Box>
  );
}

export default InterviewFeedback;

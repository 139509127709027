import React from "react";

import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";

function Notifications() {
  return (
    <IconButton
      size="large"
      aria-label="No new notifications"
      color="inherit"
      sx={{ m: 2 }}
    >
      <Badge badgeContent={0} color="error">
        <NotificationsIcon />
      </Badge>
    </IconButton>
  );
}

export default Notifications;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import {
  GlobalProvider,
  GlobalContext,
  ConvoProvider,
  ConvoContext,
} from "./Context";
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalProvider value={GlobalContext}>
    <ConvoProvider value={ConvoContext}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ConvoProvider>
  </GlobalProvider>
);

import React from "react";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarRateIcon from "@mui/icons-material/StarRate";
import { Typography } from "@mui/material";

function StarRating({
  relevancyRating,
  setRelevancyRating,
  overallRating,
  setOverallRating,
}) {
  return (
    <div>
      <Typography variant="subtitle1">
        Questions were relevant to my role:
      </Typography>

      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <span key={index}>
            {index <= relevancyRating ? (
              <StarRateIcon
                fontSize="medium"
                sx={{ color: "#D4AC0D" }}
                onClick={() => setRelevancyRating(index)}
              />
            ) : (
              <StarBorderIcon onClick={() => setRelevancyRating(index)} />
            )}
          </span>
        );
      })}

      <Typography variant="subtitle1">
        Overall I had a smooth interview experience:
      </Typography>

      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <span key={index}>
            {index <= overallRating ? (
              <StarRateIcon
                fontSize="medium"
                sx={{ color: "#D4AC0D" }}
                onClick={() => setOverallRating(index)}
              />
            ) : (
              <StarBorderIcon onClick={() => setOverallRating(index)} />
            )}
          </span>
        );
      })}
    </div>
  );
}

export default StarRating;

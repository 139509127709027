/* eslint-disable react/no-unescaped-entities */
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { GlobalContext } from "../../../../Context";
import Loading from "../../../../components/Loading";
import Snack from "../../../../components/Snack";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
});

const Form = () => {
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  const { isLoading } = globalCtx;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [forgot, setForgot] = useState(false);

  const navigate = useNavigate();

  const initialValues = {
    email: "",
  };

  useEffect(() => {
    if (forgot) {
      navigate("/reset-password");
    }
  }, [forgot]);

  const onSubmit = (values) => {
    setGlobalCtx((prev) => ({
      ...prev,
      isLoading: true,
    }));

    fetch("https://app.interviewbot.ai:5000/forgot-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BACKEND_API_KEY}`,
      },
      body: JSON.stringify({
        email: values.email,
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          setSnackbarMessage("Check your inbox for the password reset link!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setForgot(true);
        } else if (res.status === 401) {
          setSnackbarMessage("Unauthorized!");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        } else if (res.status >= 500) {
          setSnackbarMessage("Internal server error");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
        setGlobalCtx((prev) => ({
          ...prev,
          isLoggedIn: false,
          isLoading: false,
        }));
      })
      .catch((error) => {
        setGlobalCtx((prev) => ({
          ...prev,
          isLoggedIn: false,
          isLoading: false,
        }));
        setSnackbarMessage("Error in request");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.log(`Error: ${error}`);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      {isLoading && <Loading />}
      <Snack
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />

      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"textSecondary"}
        >
          Recover account
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Forgot your password?
        </Typography>
        <Typography color="text.secondary">
          Enter your email address below and we'll get you back on track.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={"100%"}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button
                  size={"large"}
                  variant={"outlined"}
                  component={Link}
                  href={"/page-login"}
                >
                  Back to login
                </Button>
              </Box>
              <Button size={"large"} variant={"contained"} type={"submit"}>
                Send reset link
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;

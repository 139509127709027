import React from "react";
import { useState } from "react";

import { Box, Container, Typography, Grid } from "@mui/material";
import AlbumIconRoundedIcon from "@mui/icons-material/AlbumRounded";
import StopCircleRoundedIcon from "@mui/icons-material/StopCircleRounded";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import MicIcon from "@mui/icons-material/Mic";

const Controls = ({
  startSpeech,
  stopSpeech,
  isSpeaking,
  submitInterview,
  startInterview,
  handleStartInterview,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  return (
    <Box sx={{ backgroundColor: "#F2F2F2" }}>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={0}
          sx={{ padding: "5px 0", alignItems: "center" }}
        >
          <Grid
            item
            xs={3}
            md={2}
            sm={1}
            sx={{
              textAlign: "center",
              marginTop: {
                xs: "18px",
                md: "0px",
              },
            }}
          >
            {isRecording ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AlbumIconRoundedIcon
                  fontSize="medium"
                  sx={{ color: "#FF0000" }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#818181",
                    fontWeight: 400,
                    marginLeft: "3px",
                  }}
                >
                  REC 00:00:00
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}></Box>
            )}
          </Grid>
          <Grid item xs={0} md={2}></Grid>
          <Grid
            id="answer-button"
            item
            xs={4}
            md={2}
            sm={1}
            sx={{
              textAlign: "center",
              marginTop: {
                xs: "18px",
                md: "0px",
              },
            }}
          >
            {isSubmit ? (
              <MicIcon
                fontSize="large"
                color="disabled"
                cursor="pointer"
                id="mic-icon"
              />
            ) : (
              <>
                {isSpeaking ? (
                  <MicIcon
                    fontSize="large"
                    color="primary"
                    cursor="pointer"
                    onClick={stopSpeech}
                    id="mic-icon"
                  />
                ) : (
                  <MicIcon
                    fontSize="large"
                    color={startInterview ? "secondary" : "disabled"}
                    cursor="pointer"
                    onClick={startSpeech}
                    id="mic-icon"
                  />
                )}
              </>
            )}

            {isSubmit ? (
              <>
                {" "}
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "#8F8F8F",
                    fontWeight: 400,
                    cursor: "pointer",
                  }}
                >
                  {isSpeaking ? "Finish" : "Answer"}
                </Typography>
              </>
            ) : (
              <>
                {" "}
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "#4F4F4F",
                    fontWeight: 400,
                    cursor: "pointer",
                  }}
                >
                  {isSpeaking ? "Finish" : "Answer"}
                </Typography>
              </>
            )}
          </Grid>
          {isSubmit ? (
            <Grid
              item
              xs={4}
              md={2}
              sm={1}
              sx={{
                textAlign: "center",
                marginTop: {
                  xs: "18px",
                  md: "0px",
                },
                marginLeft: "10px",
              }}
            >
              <StopCircleRoundedIcon fontSize="large" color="disabled" />
              <Typography
                sx={{ fontSize: "18px", color: "#8F8F8F", fontWeight: 400 }}
              >
                End interview
              </Typography>
            </Grid>
          ) : (
            <Grid
              id="start-interview"
              item
              xs={4}
              md={2}
              sm={1}
              sx={{
                textAlign: "center",
                marginTop: {
                  xs: "18px",
                  md: "0px",
                },
                marginLeft: "10px",
              }}
            >
              {startInterview ? (
                <>
                  <StopCircleRoundedIcon
                    fontSize="large"
                    sx={{ color: "#4F4F4F" }}
                    cursor="pointer"
                    onClick={() => {
                      setIsSubmit(true);
                      submitInterview();
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#4F4F4F",
                      fontWeight: 400,
                      cursor: "pointer",
                    }}
                  >
                    End interview
                  </Typography>
                </>
              ) : (
                <>
                  <PlayCircleRoundedIcon
                    fontSize="large"
                    sx={{ color: "#4F4F4F" }}
                    cursor="pointer"
                    onClick={() => {
                      handleStartInterview();
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#4F4F4F",
                      fontWeight: 400,
                      cursor: "pointer",
                    }}
                  >
                    Start interview
                  </Typography>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default Controls;

import React, { useState, createContext } from "react";
import { useCookies } from "react-cookie";

export const GlobalContext = createContext();
export const ConvoContext = createContext();

export const GlobalProvider = (props) => {
  const ctx = {
    isLoggedIn: false,
    jwtToken: "",
    user: {},
    interviewCount: 0,
    email: "",
    role: "ds",
    voice: "fus",
    duration: 15,
    isLoading: false,
  };
  const [globalCtx, setGlobalCtx] = useState(ctx);
  const [cookies] = useCookies(["session"]);

  if (
    !globalCtx.isLoggedIn &&
    cookies.session &&
    cookies.session !== "undefined"
  ) {
    const session = cookies.session.split(";");
    const user = session[2].split("=")[1];
    setGlobalCtx((prev) => ({
      ...prev,
      isLoggedIn: true,
      jwtToken: session[1].split("=")[1],
      user: {
        first_name: user.split(":")[0],
        last_name: user.split(":")[1],
        email: user.split(":")[2],
      },
    }));
  }

  return (
    <GlobalContext.Provider value={[globalCtx, setGlobalCtx]}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export const ConvoProvider = (props) => {
  const [chatHistory, setChatHistory] = useState([]);

  const [convo, setConvo] = useState({
    model: "gpt-3.5-turbo",
    messages: [
      {
        role: "assistant",
        content:
          "Hello. Thank you for joining this interview. Could you please tell me a little bit about yourself?",
      },
    ],
  });

  return (
    <ConvoContext.Provider
      value={[convo, setConvo, chatHistory, setChatHistory]}
    >
      {props.children}
    </ConvoContext.Provider>
  );
};

import { useState, useEffect, useContext } from "react";

import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";

import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import ClosedCaptionOffIcon from "@mui/icons-material/ClosedCaptionOff";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";

import { Wave, Interviewer } from "../../assets";
import Header from "../Header";
import ChatBox from "./ChatBox";
import Video from "./Video";
import VideoToggle from "./VideoToggle";

import { ConvoContext, GlobalContext } from "../../Context";
import Loading from "../Loading";
import Snack from "../Snack";
import Controls from "./Controls";
import SubmitDialog from "./SubmitDialog";

function InterviewBot() {
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  const [isVideo, setIsVideo] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const gcloudApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const { voice, isLoading } = globalCtx;
  const [captionOpened, setCaptionOpened] = useState(false);
  const [startInterview, setStartInterview] = useState(false);

  const [convo, setConvo, chatHistory, setChatHistory] =
    useContext(ConvoContext);
  const [end, setEnd] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarDuration, setSnackbarDuration] = useState(3500);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [payload, setPayload] = useState({});

  function startSpeech() {
    // isSpeaking false, start speech
    setIsSpeaking(true);
    recognition.start();
  }

  function stopSpeech() {
    // isSpeaking true, stop speech
    setIsSpeaking(false);
    recognition.stop();
    //recognition.abort();
  }

  useEffect(() => {
    if (globalCtx.interviewCount === 0) {
      const driverObj = driver({
        showProgress: true,
        showButtons: ["next", "previous"],
        animate: true,
        disableActiveInteraction: true,
        steps: [
          {
            element: "#mic-icon",
            popover: {
              side: "top",
              align: "center",
              title: "Push-to-talk",
              description:
                "Communication occurs in a push-to-talk style. To begin or end speaking, please click this button.",
            },
          },
          {
            element: "#start-interview",
            popover: {
              side: "top",
              align: "center",
              title: "Start/End Interview",
              description:
                "You can start interview when you are ready and end when your are done.",
            },
          },
          {
            element: "#my-video-box",
            popover: {
              title: "Your Video",
              description: "You will see your video here when you turn it on.",
            },
          },
          {
            element: "#video-toggle",
            popover: {
              side: "top",
              align: "center",
              title: "Toggle Video",
              description:
                "You can turn the video on/off for your convenience. You can also enable subtitles.",
            },
          },
          {
            element: "#chat-history",
            popover: {
              title: "Transcript",
              align: "center",
              description:
                "You will see your chat history here as the interview progregresses.",
            },
          },
          {
            element: "#header-menu",
            popover: {
              title: "Menu",
              description:
                "You can edit your profile or start a new interview from this menu.",
            },
          },
          {
            element: "#no-real-id",
            popover: {
              title: "Let's begin",
              description:
                "You are ready to go. Click on Start Interview to begin.",
            },
          },
        ],
      });
      driverObj.drive();
    }
  }, []);

  useEffect(() => {
    setRecognition(new window.webkitSpeechRecognition());
  }, []);

  // Text-to-speech user input
  useEffect(() => {
    if (startInterview) {
      var GPTAudio = document.getElementById("gpt-audio");
      let syntheticVoice;
      let sex;

      // Append new question to chat history
      setChatHistory((prevHistory) => {
        let chat = {
          userName: "Interview Bot",
          time: new Date().toLocaleTimeString(),
          text: question,
        };
        return [...prevHistory, chat];
      });

      // https://cloud.google.com/text-to-speech/docs/voices
      switch (voice) {
        case "fus":
          sex = "FEMALE";
          syntheticVoice = "en-US-Neural2-F";
          break;
        case "mus":
          sex = "MALE";
          syntheticVoice = "en-US-Neural2-I";
          break;
        case "fgb":
          sex = "FEMALE";
          syntheticVoice = "en-GB-News-G";
          break;
        case "mgb":
          sex = "MALE";
          syntheticVoice = "en-GB-News-M";
          break;
        case "fin":
          sex = "FEMALE";
          syntheticVoice = "en-IN-Standard-A";
          break;
        case "min":
          sex = "MALE";
          syntheticVoice = "en-IN-Standard-C";
          break;
        default:
          sex = "FEMALE";
          syntheticVoice = "en-US-Neural2-F";
      }

      const postData = {
        input: {
          text: question,
        },
        voice: {
          languageCode: "en-US",
          name: syntheticVoice,
          ssmlGender: sex,
        },
        audioConfig: {
          audioEncoding: "MP3",
        },
      };

      const reqHeaders = {
        "Content-Type": "application/json; charset=utf-8",
        "X-goog-api-key": `${gcloudApiKey}`,
      };

      async function makeReq(url, headers, data) {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });

        return response.json();
      }

      makeReq(
        "https://texttospeech.googleapis.com/v1/text:synthesize",
        reqHeaders,
        postData
      )
        .then((data) => {
          if (GPTAudio) {
            GPTAudio.src = `data:audio/mpeg;base64,${data.audioContent}`;
            GPTAudio.play();
          } else {
            console.log("no audio interface");
          }
        })
        .catch((error) => {
          console.log(`Error: ${error}`);
        });

      // cleanup
      return () => {
        if (GPTAudio) {
          GPTAudio.src = null;
        }
      };
    }
  }, [question]);

  // Get the new question from LLM
  useEffect(() => {
    if (end && answer.length > 1) {
      // Append user input to chat history
      setChatHistory((prevHistory) => {
        let chat = {
          userName: "You",
          time: new Date().toLocaleTimeString(),
          text: answer.charAt(0).toUpperCase() + answer.slice(1) + ".",
        };
        return [...prevHistory, chat];
      });

      // Fetch next question from backend
      fetch("https://app.interviewbot.ai:5000/llm-responses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Auth-Token": globalCtx.jwtToken,
        },
        body: JSON.stringify({
          interview_id: globalCtx.interviewID,
          role: globalCtx.role,
          response: answer,
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            res.json().then((json_data) => {
              setQuestion(json_data.question);
              setConvo((prevConvo) => {
                let messages = prevConvo.messages;
                messages.push({
                  role: "assistant",
                  content: json_data.question,
                });
                return {
                  ...prevConvo,
                  messages,
                };
              });
            });
          } else if (res.status === 400) {
            console.log("Bad Request");
            console.log(res.statusText);
          } else if (res.status === 401) {
            console.log("Authentication Error");
          } else if (res.status >= 500) {
            console.log("Internal Server Error");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [answer, end]);

  // Handle on speech
  useEffect(() => {
    if (!!recognition) {
      recognition.continuous = true;
      recognition.lang = "en-US";
      recognition.interimResults = false;

      recognition.onresult = (event) => {
        setEnd(false);
        let result = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        setAnswer(result);
      };

      recognition.onerror = (event) => {
        console.error("onerror event captured: ", event.error);
      };

      recognition.onend = () => {
        setEnd(true);
        recognition.stop();
      }; // end if

      // cleanup
      return () => {
        recognition.onresult = () => {};
        recognition.onerror = () => {};
        recognition.onend = () => {};
      };
    }
  }, [recognition, isSpeaking]);

  // Start Interview
  function handleStartInterview() {
    // Create placeholder for the interview
    fetch("https://app.interviewbot.ai:5000/interviews", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Auth-Token": globalCtx.jwtToken,
      },
      body: JSON.stringify({
        role: globalCtx.role,
        duration: globalCtx.duration,
        interviewer: globalCtx.voice,
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          setQuestion(
            `Hello ${globalCtx.user.first_name}. Thank you for joining this interview. Could you please tell me a little bit about yourself?`
          );
          setStartInterview(true);

          res.json().then((json_data) => {
            setGlobalCtx((prev) => ({
              ...prev,
              interviewID: json_data.interview.id,
            }));
          });
        } else if (res.status === 400) {
          res.json().then((json_data) => {
            setSnackbarMessage(json_data.msg);
            setSnackbarSeverity("warning");
            setSnackbarOpen(true);
          });
        } else if (res.status === 401) {
          console.log("Authentication Error");
        } else if (res.status >= 500) {
          console.log("Internal Server Error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Submit Interview
  function submitInterview() {
    var myVideo = document.getElementById("my-video");
    myVideo.srcObject = null;
    var gptAudio = document.getElementById("gpt-audio");
    gptAudio.pause();

    setPayload({
      role: globalCtx.role,
      duration: globalCtx.duration,
      interviewer: globalCtx.voice,
    });

    setDialogOpen(true);
  }

  const handleCaption = () => {
    setCaptionOpened(!captionOpened);
  };

  // Handle video on/off switch
  function handleVideoSwitch() {
    setIsVideo(!isVideo);
    var myVideo = document.getElementById("my-video");
    if (isVideo) {
      if (myVideo.srcObject) {
        myVideo.srcObject = null;
      }
    } else {
      var constraints = {
        audio: false,
        video: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function success(stream) {
          myVideo.srcObject = stream;
        });
    }
  }

  return (
    <Box>
      {isLoading && <Loading />}
      <SubmitDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        payload={payload}
        setSnackbarDuration={setSnackbarDuration}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setSnackbarOpen={setSnackbarOpen}
        snackbarOpen={snackbarOpen}
      />
      <Snack
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        duration={snackbarDuration}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
      <audio id="gpt-audio" controls hidden autoPlay />
      <Header />
      <Box
        sx={{
          minHeight: {
            xs: "130vh",
            md: "100vh",
            lg: "78vh",
            // xl: "82.5vh",
          },
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={0} sx={{ padding: "15px 0" }}>
            <Grid item xs={12} sm={12} md={12} lg={9}>
              <Box component="ul" sx={{ p: 1, m: 0 }}>
                <Card
                  component="li"
                  sx={{
                    width: "100%",
                    height: "100%",
                    flexGrow: 1,
                    borderRadius: "8px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      bottom: "0px",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#333",
                    }}
                  >
                    <img
                      src={Interviewer}
                      alt="Interviewer"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    {/* <video
                      autoPlay
                      loop
                      muted
                      id="ib-video"
                      width="100%"
                      height="100%"
                      style={{ backgroundColor: "#ddd" }}
                    >
                      <source
                        src="https://assets.codepen.io/6093409/river.mp4"
                        type="video/mp4"
                      />
                    </video> */}
                    <Box
                      id="video-toggle"
                      sx={{
                        position: "absolute",
                        textAlign: "center",
                        right: "5px",
                        bottom: "5px",
                        display: "flex",
                        alignItems: "center",
                        zIndex: 999,
                      }}
                    >
                      <VideoToggle
                        sx={{
                          m: 1,
                        }}
                        checked={isVideo}
                        label=""
                        onChange={handleVideoSwitch}
                      />
                      <ClosedCaptionOffIcon
                        fontSize="large"
                        sx={{
                          color: {
                            xs: "#666",
                            sm: "#666",
                            md: "#fefefe",
                            lg: "#fefefe",
                          },
                          display: captionOpened ? "none" : "block",
                          "& :hover": {
                            cursor: "pointer",
                          },
                        }}
                        onClick={handleCaption}
                      />
                      <ClosedCaptionIcon
                        fontSize="large"
                        sx={{
                          color: {
                            xs: "#666",
                            sm: "#666",
                            md: "#fefefe",
                            lg: "#fefefe",
                          },
                          display: captionOpened ? "block" : "none",
                          "& :hover": {
                            cursor: "pointer",
                          },
                        }}
                        onClick={handleCaption}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      opacity: [0.5, 0.5, 0.5],
                      position: "absolute",
                      textAlign: "center",
                      left: "5px",
                      bottom: "5px",
                      backgroundColor: "#fff",
                      padding: {
                        xs: "3px 6px",
                        md: "8px",
                      },
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      level="p"
                      sx={{
                        textAlign: "left",
                        fontSize: {
                          xs: "14px",
                          sm: "16px",
                          md: "20px",
                        },
                        lineHeight: "19px",
                      }}
                    >
                      Interview Bot
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      textAlign: "center",
                      top: {
                        xs: "5px",
                        sm: "8px",
                        md: "15px",
                      },
                      right: {
                        xs: "5px",
                        sm: "8px",
                        md: "15px",
                      },
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                  >
                    <Video />
                  </Box>
                  <CardContent
                    sx={{
                      position: "absolute",
                      textAlign: "center",
                      bottom: {
                        xs: "10px",
                        md: "20px",
                      },
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        padding: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {captionOpened && <img src={Wave} alt="Prompt" />}

                      <Typography
                        level="p"
                        sx={{
                          textAlign: "left",
                          color: "#CFCFCF",
                          fontSize: {
                            xs: "12px",
                            md: "16px",
                          },
                          lineHeight: "19px",
                        }}
                      >
                        {captionOpened && "Now"}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#fff",
                          marginLeft: {
                            xs: "14px",
                            sm: "18px",
                            md: "20px",
                          },
                        }}
                      >
                        {captionOpened && question}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Box component="ul" sx={{ p: 1, m: 0 }}>
                <Controls
                  startSpeech={startSpeech}
                  stopSpeech={stopSpeech}
                  isSpeaking={isSpeaking}
                  submitInterview={submitInterview}
                  startInterview={startInterview}
                  handleStartInterview={handleStartInterview}
                />
              </Box>
            </Grid>
            <Grid
              id="chat-history"
              item
              xs={12}
              sm={12}
              md={12}
              lg={3}
              sx={{ padding: "5px 10px" }}
            >
              <ChatBox />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default InterviewBot;

import * as React from "react";
import { useContext } from "react";

import { Box, Card, Typography } from "@mui/material";

import { GlobalContext } from "../../../Context";

export default function Video() {
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  return (
    <Box
      id="my-video-box"
      component="ul"
      sx={{ p: 0, m: 0, border: "3px solid #3874CB", borderRadius: "8px" }}
    >
      <Card
        component="li"
        sx={{
          width: { xs: 115, sm: 178, md: 200, lg: 180 },
          height: {
            xs: 65,
            sm: 100,
            md: 111,
            lg: 135,
          },
          flexGrow: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <video
            autoPlay
            loop
            muted
            id="my-video"
            width="100%"
            height="100%"
            poster="bot2.png"
            style={{ backgroundColor: "#fff6eb" }}
          ></video>
        </Box>
        <Box
          sx={{
            opacity: [0.5, 0.5, 0.5],
            position: "absolute",
            textAlign: "center",
            left: "5px",
            bottom: "5px",
            backgroundColor: "#fff",
            padding: "3px 5px",
            borderRadius: "8px",
          }}
        >
          <Typography
            level="p"
            sx={{
              textAlign: "left",
              fontSize: {
                xs: "10px",
              },
              lineHeight: "19px",
            }}
          >
            {globalCtx.user.first_name}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

import InterviewBot from "./components/InterviewBot";
import Feedback from "./components/Feedback";

function Main() {
  return (
    <div className="Main">
      <InterviewBot />
      <Feedback />
    </div>
  );
}

export default Main;

import Feedback from "../Feedback";
import Preform from "./Preform";

function PreForm() {
  return (
    <div className="Main">
      <Preform />
      <Feedback />
    </div>
  );
}

export default PreForm;

import React from "react";
import { CircularProgress, Typography } from "@mui/material";

const style = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
};

const Loading = () => {
  const textStyle = {
    position: "fixed",
    top: 100,
    background: "rgba(169, 169, 169, 0.5)",
    color: "#FEFEFE", // Text color
    padding: "10px", // Optional padding
  };

  return (
    <div style={style}>
      <Typography variant="body1" gutterBottom style={textStyle}>
        Please wait...
      </Typography>
      <br />
      <CircularProgress />
    </div>
  );
};

export default Loading;

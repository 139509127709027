import React, { useEffect, useState, useContext } from "react";

import { GlobalContext } from "../../Context";

import { Box, Card, CardContent, Container, Typography } from "@mui/material";

function InterviewScript({ iid }) {
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  const [interviewSession, setInterviewSession] = useState([]);

  useEffect(() => {
    fetch("https://app.interviewbot.ai:5000/llm-responses/" + iid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Auth-Token": globalCtx.jwtToken,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          console.log("Bad Request");
        } else if (res.status === 404) {
          console.log("Not Found");
        } else if (res.status === 401) {
          console.log("Authentication Error");
        } else if (res.status >= 500) {
          console.log("Internal Server Error");
        }
      })
      .then((data) => {
        if (data) {
          setInterviewSession(data.record);
        }
      })
      .catch((error) => {
        console.log(`Error: ${error}`);
      });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#FAFAFA",
        borderRadius: "16px",
        padding: "12px 18px",
        height: "58vh",
        overflowY: "scroll",
      }}
    >
      <Card>
        <Typography color="text.primary" variant="h6">
          Transcript
        </Typography>
        {interviewSession.map((item, index) => (
          <CardContent key={index} style={{ overflow: "scroll" }}>
            <Typography color="text.secondary" variant="body1" gutterBottom>
              <b>Q: </b>
              {item.q}
            </Typography>
            <Typography color="text.secondary" variant="body1" gutterBottom>
              <b>A: </b>
              {item.a}
            </Typography>
          </CardContent>
        ))}
      </Card>
    </Box>
  );
}

export default InterviewScript;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import { GlobalContext, ConvoContext } from "../../Context";
import Header from "../Header";
import RecentInterviews from "./RecentInterviews";

const Preform = () => {
  const navigate = useNavigate();
  const [convo, setConvo, chatHistory, setChatHistory] =
    useContext(ConvoContext);
  const [globalCtx, setGlobalCtx] = useContext(GlobalContext);
  const { user, role, voice, duration, jwtToken } = globalCtx;
  const [submit, setSubmit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (submit) {
      navigate("/main");
    }
  }, [submit]);

  useEffect(() => {
    setChatHistory([]);
  }, []);

  // HandleSubmit - Interview Setting
  const handleSubmit = (e) => {
    e.preventDefault();
    let Prompts = require(`../../conf/prompts/${role}`).Prompts;
    setConvo((prevConvo) => {
      let messages = prevConvo.messages.concat(Prompts).concat(
        {
          role: "system",
          content: `Wrap up the interview in ${duration} minutes.`,
        },
        {
          role: "system",
          content: `Interviewee's name is ${globalCtx.user.full_name}. Never use any other name.`,
        }
      );
      return {
        ...prevConvo,
        messages,
      };
    });

    setSubmit(true);
  };

  const handleRoleChange = (event) => {
    setGlobalCtx((prev) => ({
      ...prev,
      role: event.target.value,
    }));
  };

  const handleDurationChange = (event) => {
    setGlobalCtx((prev) => ({
      ...prev,
      duration: event.target.value,
    }));
  };

  const handleVoiceChange = (event) => {
    setGlobalCtx((prev) => ({
      ...prev,
      voice: event.target.value,
    }));
  };

  function handleDialogClose() {
    setDialogOpen(false);
  }

  return (
    <div>
      <Dialog
        open={dialogOpen}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Info"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You have reached your daily interviews limit.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
      <Header />
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={6}>
          <Box>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h4" component="div">
                  Welcome to the Interview Bot!
                </Typography>
              </CardContent>
              <CardContent>
                <Typography variant="body2" component="div">
                  Please select options for your interview from below and start
                  your mockup interview.
                </Typography>
              </CardContent>
              <FormControl onSubmit={handleSubmit}>
                <CardContent>
                  <TextField
                    onChange={handleRoleChange}
                    id="role"
                    select
                    label="Role"
                    value={role}
                    fullWidth
                    defaultValue={role}
                    size={"small"}
                    helperText="Please select a role to interview"
                  >
                    <MenuItem key="ds" value="ds">
                      Data Scientist
                    </MenuItem>
                    <MenuItem key="da" value="da">
                      Data Analyst
                    </MenuItem>
                    <MenuItem key="devops" value="devops">
                      DevOps Engineer
                    </MenuItem>
                    <MenuItem key="pm" value="pm">
                      Product Manager
                    </MenuItem>
                    <MenuItem key="pgm" value="pgm">
                      Program Manager
                    </MenuItem>
                    <MenuItem key="qa" value="qa">
                      QA Engineer
                    </MenuItem>
                    <MenuItem key="isa" value="isa">
                      Information Security Analyst
                    </MenuItem>
                  </TextField>
                </CardContent>
                <CardContent>
                  <TextField
                    onChange={handleDurationChange}
                    id="duration"
                    select
                    label="Duration"
                    fullWidth
                    value={duration}
                    defaultValue={duration}
                    size={"small"}
                    helperText="Please select the duration of the interview"
                  >
                    <MenuItem key="15m" value={15}>
                      15 Mins
                    </MenuItem>
                    <MenuItem key="30m" value={30}>
                      30 Mins
                    </MenuItem>
                    <MenuItem key="45m" value={45}>
                      45 Mins
                    </MenuItem>
                  </TextField>
                </CardContent>
                <CardContent>
                  <TextField
                    onChange={handleVoiceChange}
                    id="voice"
                    select
                    label="Interviewer"
                    fullWidth
                    value={voice}
                    defaultValue={voice}
                    size={"small"}
                    helperText="Please select your interviwer"
                  >
                    <MenuItem key="fus" value="fus">
                      Female - American English
                    </MenuItem>
                    <MenuItem key="mus" value="mus">
                      Male - American English
                    </MenuItem>
                    <MenuItem key="fin" value="fin">
                      Female - Indian English
                    </MenuItem>
                    <MenuItem key="min" value="min">
                      Male - Indian English
                    </MenuItem>
                    <MenuItem key="fgb" value="fgb">
                      Female - British English
                    </MenuItem>
                    <MenuItem key="mgb" value="mgb">
                      Male - British English
                    </MenuItem>
                  </TextField>
                </CardContent>
                <CardContent>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Start Interview
                  </Button>
                </CardContent>
              </FormControl>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={
              {
                // minWidth: {
                //   xs: "95vh",
                //   md: "95vh",
                //   lg: "95vh",
                //   // xl: "82.5vh",
                // },
              }
            }
          >
            <RecentInterviews />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Preform;

import React, { useContext, useEffect, useRef } from "react";

import { Box, Typography, Alert } from "@mui/material";

import Chat from "../Chat";
import { ConvoContext } from "../../../Context";

const ChatBox = () => {
  const [convo, setConvo, chatHistory, setChatHistory] =
    useContext(ConvoContext);
  const chatEndRef = useRef(null);
  const scrollToBottom = () => {
    chatEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [convo]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "15px",
        }}
      >
        <Typography variant="h5" paddingLeft={5}>
          Interview Script
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FAFAFA",
          borderRadius: "16px",
          padding: "12px 18px",
          height: "58vh",
          overflowY: "scroll",
        }}
      >
        <Alert severity="info">Video is being streamed on your local browser purely for the sake of interview experience. We <strong>do not record</strong> video.</Alert>
        <Box sx={{ padding: "10px 0" }}>
          {chatHistory?.map((item, index) => (
            <Chat item={item} key={index} />
          ))}
        </Box>
        <div ref={chatEndRef} />
      </Box>
    </div>
  );
};

export default ChatBox;
